import React from 'react';
import './feed.scss';

const feed = ({ data }) => {
  
  return (
    <div className="feed">
      {data.edges.map(edge => (
        <div className="feed-images" key={edge.node.id}>
          <img src={edge.node.picture.fluid.src} alt={edge.node.title} />
        </div>
      ))}
    </div>
  );
};

export default feed;








