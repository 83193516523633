import React from "react";
import Navigation from '../components/navigation';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Feed from "../components/feed";
import { graphql } from 'gatsby';
import Footer from '../components/footer';

const landscape = (props) => {

  const landscapeContent = props.data.allContentfulWork;
  
  return (
    <Layout>
      <SEO title="Landscape" keywords={[`photographer`, `fotograf`, `dusseldorf`, `düsseldorf`, `germany`, `写真家`, `portrait`, `porträt`, `カメラマン`, `TFP`, `landscape`, `hobby`, `japanese`, `street`, `outdoor`]}  />
      <Navigation landscape={true} />
      <Feed data={landscapeContent} />  
      <Footer />
    </Layout>
  );
};

export default landscape;

export const query = graphql` 
query LandscapeQuery {
  allContentfulWork(
      sort: { fields: [createdAt], order: DESC }
      filter: {
      node_locale: {eq: "en-US"},
      category: {category: {eq: "landscape"}},
    }
    ) {
    edges {
      node {
        id
        title
        picture {
          fluid(maxWidth: 600, quality: 95) {
            src
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
}
`
